exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-templates-country-js": () => import("./../../../src/templates/Country.js" /* webpackChunkName: "component---src-templates-country-js" */),
  "component---src-templates-searches-js": () => import("./../../../src/templates/Searches.js" /* webpackChunkName: "component---src-templates-searches-js" */),
  "component---src-templates-telephone-js": () => import("./../../../src/templates/Telephone.js" /* webpackChunkName: "component---src-templates-telephone-js" */),
  "component---src-templates-use-cases-js": () => import("./../../../src/templates/UseCases.js" /* webpackChunkName: "component---src-templates-use-cases-js" */)
}

